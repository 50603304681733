.navbar-wrapper{
  display: flex;
  width: 100%;
  margin-top: -15px;
}

.home-icon-wrapper {
  text-align: center;
}

.sign-out-icon-wrapper {
  text-align: center;
}

.icon-label {
  font-size: 12px;
  margin-top: -25px;
  color: #434242;
}

.home-icon {
  color: #6a6a6a;
}

.sign-out-icon {
  color: #6a6a6a;
}