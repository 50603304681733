.page-content {
  margin-top: 5%;
  margin-bottom: 5%;
}

.page-header-wrapper {
  display: flex;
  flex-direction: row;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  border-style: solid;
  border-color: #7f7f7f;
}

.date-label {
  display: flex;
  flex: 1;
  font-size: 18px;
  line-height: 18px;
}

.apmc-label {
  font-size: 18px;
  line-height: 18px;
}

.filter-items-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 18px;
}

.filter-item-header {
  font-weight: bold;
}

.filter-item-category-content {
  display: flex;
  flex-direction: row;
}

.filter-item-category {
  margin-left: 15px;
}

.filter-item-category * {
  display: inline-flex;
  font-size: 16px;
}

.filter-item-category label {
  display: inline-flex !important;
}

.asin-list-table-cell {
  font-size: 14px !important;
  border-bottom: 1px solid #d0d4d4;
  table-layout: fixed !important;
}

.asin-table-wrapper {
  margin-bottom: 25%;
}

.bottom-search-bar {
  position: fixed;
  bottom:0;
  width: 95%;
  padding-bottom: 30px;
  font-size: 20px;
}

.loader-wrapper {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.no-buying-plan-text {
  margin-top: 15%;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}

.no-curr-day-buying-plan-text {
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 16px;
  line-height: 20px;
}
