.table-style {
    border: 1px solid black;
    border-collapse: collapse;
    margin-left:auto;
    margin-right:auto;
    margin-top: 5%; 
    width: fit-content;
    font-size: medium;
    height: 50px;
    text-align: center;
    padding: 2%;
}

.asin-view-page-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.asin-date-component-table-style {
  border: 1px solid black;
  border-collapse: collapse;
  margin-left:auto;
  margin-right:auto;
  margin-top: 5%;
  width: 60%;
  font-size: medium;
  height: 50px;
  text-align: center;
  padding: 2%;
}

.table-row-style {
    border: 1px solid black;
    border-collapse: collapse;
}

.desired-quality-spec {
    display: flex;
    width: fit-content;
    height: fit-content;
    border: 1px solid #077398;
    background: #077398;
    color: white;
    font-size: medium;
    margin: 5% auto auto;
    text-align: center;
    padding: 2%;
    font-weight: bold;
  }

  .save-button-container {
    display: flex;
    margin-top: 5%;
    justify-content: center;
  }

  .save-button {
    width: 25%;
    height: 50px;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
    font-size: medium;
    text-align: center;
    font-weight: bold;
  }

  .input-class {
    height: 100%;
    text-align: center;
  }

.modal-content {
  display:flex;
  flex-direction: column;
  margin: 10px;
  --width: 100%;
  --height: 100px;
  font-size: 18px;
  font-family: Amazon Ember, Arial, sans-serif;
}

.bold-font {
  font-weight: bold;
}

.condition-statement-style {
  margin-left: 5%;
  margin-top: 5px;
  font-size: 14px;
}

div[aria-describedby="modal-description"] {
  position: unset;
}

.cell-text-style {
  display: flex;
  flex: 1;
  width: fit-content;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
}

.super-script-style {
  margin-left: 2px;
  font-size: 10px;
  line-height: 15px;
}

.table-wrapper-style {
  border-collapse: collapse;
  margin-left:auto;
  margin-right:auto;
  margin-top: 5%;
  width: 90%;
}
