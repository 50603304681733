.name-display-box {
  background:#8ed7cf;
  border-style: solid;
  border-width: 2px;
  border-color: #1bae9f;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  width: 70%;
  text-align: center;
}

.welcome-text-style {
  line-height: 60px;
  font-size: 25px;
}

.select-apmc-label-text-style {
  text-align: center;
  font-size: 20px;
  margin-top:10%;
  margin-bottom: 10%;
}

.apmc-dropdown-wrapper {
  display: flex;
  flex-direction: row;
  width: 70%;
  height: 10%;
  padding: 8px;
  margin-left: auto;
  margin-right: auto;
  border-style: solid;
  border-color: #7f7f7f;
}

.apmc-dropdown-label {
  font-size: 20px;
  margin-right: 5%;
  line-height: 35px;
}

.apmc-dropdown-menu {
  font-size: 18px;
  flex: 1
}

.apmc-picker-label {
  text-align: center;
  font-size: 45px;
  line-height: 35px;
}

.apmc-picker {
  height: auto;
  flex: 1;
  font-size: 25px;
  width: 70%;
}

.confirm-button-wrapper {
  display: flex;
  margin-top: 20%;
  justify-content: center;
}

.selection-screen-button-style {
  display: flex;
  width: 25%;
  --font-size: 20px
}

